import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import {
  getAccessToken,
  getAuthJwt,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"

import { Authentication } from "@flash-hros/auth-helper"
import { PropsWithChildren } from "react"

const { CognitoSignOut } = Authentication
const { HROS_CARD_LOGISTICS_BFF_URL } = process.env

const httpLink = new HttpLink({
  uri: HROS_CARD_LOGISTICS_BFF_URL,
})

const authLink = onError(({ graphQLErrors, operation }) => {})

const cache = new InMemoryCache({
  addTypename: false,
})

export const CustomApolloProvider = ({ children }: PropsWithChildren) => {
  const { selectedCompany } = useSelectedCompany()
  const contextLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken()
    const cognitoToken = await getAuthJwt()

    const context = {
      headers: {
        ...headers,
        ...(selectedCompany && {
          companyid: selectedCompany.id,
        }),
        ...(accessToken && {
          Authorization: `Bearer ${accessToken}`,
          'X-Flash-Auth': `Bearer ${accessToken}`,
        }),
        ...(cognitoToken && {
          "cognito-access-token": `Bearer ${cognitoToken}`,
        }),
      },
    }
    return context
  })

  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, authLink, httpLink]),
    cache,
    resolvers: {},
  })

  client.onClearStore(async () => {
    await CognitoSignOut({})
    return
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
